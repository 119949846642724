import React, { useEffect } from "react";
import { handleHasAdminRole } from "../utilities/roleHandler";
import { ServiceCard, UIKit } from "@egdeconsulting/ekom_lib";
import { ContentManagament } from "../assets/icons/contentManagement";
import { UseManagement } from "../assets/icons/useManagement";
import { UserManagement } from "../assets/icons/userManagement";
import { OrganisationManagement } from "../assets/icons/organisationManagement";

export const Landing: React.FC = () => {
  useEffect(() => {
    handleHasAdminRole();
  }, []);

  return (
    <UIKit.Section>
      <UIKit.Container>
        <h1 className="uk-text-center">Hva vil du gjøre?</h1>
        <UIKit.Grid
          match={true}
          className="uk-child-width-1-3@m uk-child-width-1-2@s"
        >
          <div>
            <ServiceCard
              hover
              icon={<ContentManagament />}
              link="content-management"
              linkText="Rediger innhold"
              title="Rediger innhold i Ekomportalen"
            >
              <p className="uk-margin-medium-bottom uk-text-left">
                Rediger tekst og lenker som vises i Ekomportalen.
              </p>
            </ServiceCard>
          </div>
          <div>
            <ServiceCard
              hover
              icon={<UseManagement />}
              link="limits"
              linkText="Administrer bruk"
              title="Administrer bruk"
            >
              <p className="uk-margin-medium-bottom  uk-text-left">
                Administrer antall tillatte søk og sett min/maks-område for søk.
              </p>
            </ServiceCard>
          </div>
          <div>
            <ServiceCard
              hover
              icon={<UserManagement />}
              link="user-management"
              linkText="Administrer brukere"
              title="Administrer brukere"
            >
              <p className="uk-margin-medium-bottom  uk-text-left">
                Se oversikt over brukere, hvem som er blokkerte, samt blokkere
                og oppheve blokkering.
              </p>
            </ServiceCard>
          </div>
          <div>
            <ServiceCard
              hover
              icon={<OrganisationManagement />}
              link="organisation-management"
              linkText="Administrer virksomheter"
              title="Administrer virksomheter"
            >
              <p className="uk-margin-medium-bottom  uk-text-left">
                Se oversikt over virksomheter, hvilke virksomheter som er
                blokkerte, samt blokkere og oppheve blokkering.
              </p>
            </ServiceCard>
          </div>
        </UIKit.Grid>
      </UIKit.Container>
    </UIKit.Section>
  );
};

export default Landing;
